/* src/components/Header/Header.svelte generated by Svelte v3.48.0 */
import {
	SvelteComponent,
	append_hydration,
	attr,
	children,
	claim_component,
	claim_element,
	claim_space,
	claim_text,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	init,
	insert_hydration,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	space,
	src_url_equal,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "svelte/internal";

import AutoComplete from '../AutoComplete/AutoComplete.svelte';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	return child_ctx;
}

// (48:8) {#if open}
function create_if_block_1(ctx) {
	let div;
	let each_value_1 = /*navItems*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			this.h();
		},
		l(nodes) {
			div = claim_element(nodes, "DIV", { class: true });
			var div_nodes = children(div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].l(div_nodes);
			}

			div_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(div, "class", "hamburger-menu font-family-roboto svelte-jp7jro");
		},
		m(target, anchor) {
			insert_hydration(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*navItems, permalink*/ 5) {
				each_value_1 = /*navItems*/ ctx[2];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (50:12) {#each navItems as n}
function create_each_block_1(ctx) {
	let a;
	let t_value = /*n*/ ctx[7].label + "";
	let t;
	let a_href_value;

	return {
		c() {
			a = element("a");
			t = text(t_value);
			this.h();
		},
		l(nodes) {
			a = claim_element(nodes, "A", { href: true, class: true });
			var a_nodes = children(a);
			t = claim_text(a_nodes, t_value);
			a_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(a, "href", a_href_value = /*n*/ ctx[7].permalink);
			attr(a, "class", "svelte-jp7jro");
			toggle_class(a, "active", /*n*/ ctx[7].permalink === /*permalink*/ ctx[0]);
		},
		m(target, anchor) {
			insert_hydration(target, a, anchor);
			append_hydration(a, t);
		},
		p(ctx, dirty) {
			if (dirty & /*navItems*/ 4 && t_value !== (t_value = /*n*/ ctx[7].label + "")) set_data(t, t_value);

			if (dirty & /*navItems*/ 4 && a_href_value !== (a_href_value = /*n*/ ctx[7].permalink)) {
				attr(a, "href", a_href_value);
			}

			if (dirty & /*navItems, permalink*/ 5) {
				toggle_class(a, "active", /*n*/ ctx[7].permalink === /*permalink*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(a);
		}
	};
}

// (57:8) {#each navItems as n}
function create_each_block(ctx) {
	let a;
	let t_value = /*n*/ ctx[7].label + "";
	let t;
	let a_href_value;

	return {
		c() {
			a = element("a");
			t = text(t_value);
			this.h();
		},
		l(nodes) {
			a = claim_element(nodes, "A", { href: true, class: true });
			var a_nodes = children(a);
			t = claim_text(a_nodes, t_value);
			a_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(a, "href", a_href_value = /*n*/ ctx[7].permalink);
			attr(a, "class", "svelte-jp7jro");
			toggle_class(a, "active", /*n*/ ctx[7].permalink === /*permalink*/ ctx[0]);
		},
		m(target, anchor) {
			insert_hydration(target, a, anchor);
			append_hydration(a, t);
		},
		p(ctx, dirty) {
			if (dirty & /*navItems*/ 4 && t_value !== (t_value = /*n*/ ctx[7].label + "")) set_data(t, t_value);

			if (dirty & /*navItems*/ 4 && a_href_value !== (a_href_value = /*n*/ ctx[7].permalink)) {
				attr(a, "href", a_href_value);
			}

			if (dirty & /*navItems, permalink*/ 5) {
				toggle_class(a, "active", /*n*/ ctx[7].permalink === /*permalink*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(a);
		}
	};
}

// (62:4) {#if !hideAutocomplete}
function create_if_block(ctx) {
	let autocomplete;
	let current;
	autocomplete = new AutoComplete({ props: { large } });

	return {
		c() {
			create_component(autocomplete.$$.fragment);
		},
		l(nodes) {
			claim_component(autocomplete.$$.fragment, nodes);
		},
		m(target, anchor) {
			mount_component(autocomplete, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(autocomplete.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(autocomplete.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(autocomplete, detaching);
		}
	};
}

function create_fragment(ctx) {
	let header;
	let div3;
	let nav;
	let a;
	let img;
	let img_src_value;
	let t0;
	let div1;
	let div0;
	let span0;
	let t1;
	let span1;
	let t2;
	let span2;
	let t3;
	let t4;
	let div2;
	let t5;
	let current;
	let mounted;
	let dispose;
	let if_block0 = /*open*/ ctx[1] && create_if_block_1(ctx);
	let each_value = /*navItems*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block1 = !/*hideAutocomplete*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			header = element("header");
			div3 = element("div");
			nav = element("nav");
			a = element("a");
			img = element("img");
			t0 = space();
			div1 = element("div");
			div0 = element("div");
			span0 = element("span");
			t1 = space();
			span1 = element("span");
			t2 = space();
			span2 = element("span");
			t3 = space();
			if (if_block0) if_block0.c();
			t4 = space();
			div2 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t5 = space();
			if (if_block1) if_block1.c();
			this.h();
		},
		l(nodes) {
			header = claim_element(nodes, "HEADER", { class: true, role: true });
			var header_nodes = children(header);
			div3 = claim_element(header_nodes, "DIV", { class: true });
			var div3_nodes = children(div3);
			nav = claim_element(div3_nodes, "NAV", { class: true });
			var nav_nodes = children(nav);
			a = claim_element(nav_nodes, "A", { href: true, class: true });
			var a_nodes = children(a);

			img = claim_element(a_nodes, "IMG", {
				src: true,
				class: true,
				alt: true,
				width: true,
				height: true
			});

			a_nodes.forEach(detach);
			t0 = claim_space(nav_nodes);
			div1 = claim_element(nav_nodes, "DIV", { class: true });
			var div1_nodes = children(div1);
			div0 = claim_element(div1_nodes, "DIV", { role: true, class: true });
			var div0_nodes = children(div0);
			span0 = claim_element(div0_nodes, "SPAN", { class: true });
			children(span0).forEach(detach);
			t1 = claim_space(div0_nodes);
			span1 = claim_element(div0_nodes, "SPAN", { class: true });
			children(span1).forEach(detach);
			t2 = claim_space(div0_nodes);
			span2 = claim_element(div0_nodes, "SPAN", { class: true });
			children(span2).forEach(detach);
			div0_nodes.forEach(detach);
			t3 = claim_space(div1_nodes);
			if (if_block0) if_block0.l(div1_nodes);
			div1_nodes.forEach(detach);
			t4 = claim_space(nav_nodes);
			div2 = claim_element(nav_nodes, "DIV", { class: true });
			var div2_nodes = children(div2);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].l(div2_nodes);
			}

			div2_nodes.forEach(detach);
			nav_nodes.forEach(detach);
			t5 = claim_space(div3_nodes);
			if (if_block1) if_block1.l(div3_nodes);
			div3_nodes.forEach(detach);
			header_nodes.forEach(detach);
			this.h();
		},
		h() {
			if (!src_url_equal(img.src, img_src_value = "/site-images/logo.svg")) attr(img, "src", img_src_value);
			attr(img, "class", "logo svelte-jp7jro");
			attr(img, "alt", "findenergy-logo");
			attr(img, "width", "215");
			attr(img, "height", "36");
			attr(a, "href", /*homeLink*/ ctx[4]);
			attr(a, "class", "svelte-jp7jro");
			attr(span0, "class", "line svelte-jp7jro");
			attr(span1, "class", "line svelte-jp7jro");
			attr(span2, "class", "line svelte-jp7jro");
			attr(div0, "role", "button");
			attr(div0, "class", "hamburger-btn svelte-jp7jro");
			attr(div1, "class", "mobile-menu svelte-jp7jro");
			attr(div2, "class", "tablet-menu font-family-roboto svelte-jp7jro");
			attr(nav, "class", "container svelte-jp7jro");
			attr(div3, "class", "header-wrapper svelte-jp7jro");
			attr(header, "class", "header svelte-jp7jro");
			attr(header, "role", "navigation");
		},
		m(target, anchor) {
			insert_hydration(target, header, anchor);
			append_hydration(header, div3);
			append_hydration(div3, nav);
			append_hydration(nav, a);
			append_hydration(a, img);
			append_hydration(nav, t0);
			append_hydration(nav, div1);
			append_hydration(div1, div0);
			append_hydration(div0, span0);
			append_hydration(div0, t1);
			append_hydration(div0, span1);
			append_hydration(div0, t2);
			append_hydration(div0, span2);
			append_hydration(div1, t3);
			if (if_block0) if_block0.m(div1, null);
			append_hydration(nav, t4);
			append_hydration(nav, div2);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div2, null);
			}

			append_hydration(div3, t5);
			if (if_block1) if_block1.m(div3, null);
			current = true;

			if (!mounted) {
				dispose = listen(div0, "click", /*click_handler*/ ctx[6]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*open*/ ctx[1]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(div1, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty & /*navItems, permalink*/ 5) {
				each_value = /*navItems*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div2, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (!/*hideAutocomplete*/ ctx[3]) if_block1.p(ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header);
			if (if_block0) if_block0.d();
			destroy_each(each_blocks, detaching);
			if (if_block1) if_block1.d();
			mounted = false;
			dispose();
		}
	};
}

let large = true;

function instance($$self, $$props, $$invalidate) {
	let { server } = $$props;
	let { permalink } = $$props;
	const hideAutocomplete = permalink === '/' || permalink === '/dev/' || permalink === '/rates/' || permalink === '/dev/rates/';
	let open = false;

	let navItems = [
		{
			permalink: '/power-outage/',
			label: 'Power Outage'
		},
		{ permalink: '/solar/', label: 'Solar' },
		{ permalink: '/wind/', label: 'Wind' },
		{
			permalink: '/usda-reap-grant/',
			label: 'Grants'
		},
		{
			permalink: '/electricity/',
			label: 'Electricity'
		},
		// { permalink: '/natural-gas/', label: 'Natural Gas' },
		{
			permalink: '/providers/',
			label: 'Providers'
		},
		// { permalink: '/power-plants/', label: 'Power Plants' },
		{
			permalink: '/#states-list',
			label: 'States'
		},
		{
			permalink: '/use-our-data/',
			label: 'Use Our Data'
		}
	]; // { permalink: '/mission/', label: 'Mission' },
	// { permalink: '/contact/', label: 'Contact' },

	const homeLink = server ? '/dev/' : '/';

	if (server) {
		navItems = navItems.map(n => {
			return { ...n, permalink: `/dev${n.permalink}` };
		});
	}

	const click_handler = () => $$invalidate(1, open = !open);

	$$self.$$set = $$props => {
		if ('server' in $$props) $$invalidate(5, server = $$props.server);
		if ('permalink' in $$props) $$invalidate(0, permalink = $$props.permalink);
	};

	return [permalink, open, navItems, hideAutocomplete, homeLink, server, click_handler];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { server: 5, permalink: 0 });
	}
}

export default Header;